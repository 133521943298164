import * as React from "react";
import {Link} from 'gatsby';
import '../assets/app.scss';
import Seo from "../components/seo";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { seoLandingTY } from "../data/seo";
import {Helmet} from 'react-helmet';


const ThankYouLanding = () => {

    return (
        <div className="thank-you-landing">

            <Seo
                title={seoLandingTY.title}
                description={seoLandingTY.description}
            />
            

            <Helmet
                bodyAttributes={{
                    class: 'bk-white'
                }}
            />
            

            <div className="container-custom border-bottom">
                <div className="row">
                    <div className="col-12 p-0">
                        <Header
                            showMenu={false}
                        />
                    </div>
                </div>
            </div>

            <div className="thank-you-content">
                <div className="container-custom">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6 text-center">
                            <img src="/thank-you-landing.svg" alt="icon" />
                            <h1 className="heading-1 text-center mb-3 mt-5">¡Gracias por contactarnos!</h1>
                            <p className="fs-20 text-grey text-center text-medium mb-5">Tu consulta fue enviada y breve nos estaremos comunicando con vos.</p>
                            <Link 
                                className="btn-cd-primary"
                                to="/"
                            >
                                Volver al inicio
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bk-cd-primary">
                <div className="container-custom">
                    <div className="row">
                        <div className="col-12">
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ThankYouLanding;
